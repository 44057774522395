<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useNetw from "@useNetw";
import Swal from "sweetalert2";
// import btnComponent from "@/components/btnComponent";
import Multiselect from "vue-multiselect";
// import DatePicker from "vue2-datepicker";
export default {
  page: {
    title: "เพิ่มข้อมูลอนุมัติโอนรถข้ามสาขา",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader, Multiselect },
  data() {
    return {
      // tableData: tableData,

      title: "เพิ่มข้อมูลอนุมัติโอนรถข้ามสาขา",
      items: [
        {
          text: "คลังสินค้า",
          active: true,
        },
        {
          text: "อนุมัติโอนรถข้ามสาขา",
          active: false,
          href: "/approve-vehicle-transfer",
        },
        {
          text: "เพิ่มข้อมูลอนุมัติโอนรถข้ามสาขา",
          active: true,
        },
      ],
      rowsSelect: [],
      chk: "",
      selected: [],
      rowsTotalpages: "",
      rowsperPage: "",
      rowsPage: "",
      rowsProduct: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      arrTest: [],
      totalRecords: 0,
      pageOptions: [10, 25, 50, 100],
      optionBranch: [],
      rowsDetail: [],
      loading: false,
      userBranchAccessId: "",
      submitted: false,
      searchform: "",
      selectedPO: [],
      interiorColor: "",
      exteriorColor: "",
      optioninteriorColor: [],
      optionExteriorColor: [],
      branchApproveVehicleTransferDetail: [],

      vehicle: [],
      userBranchId: "",
      rcvdDate: "",
      dueDate: "",
      credit: "",
      taxInvoiceDate: "",
      taxInvoiceNumber: "",
      shippingDate: "",
      shippingNumber: "",
      stock: "",
      supId: "",
      transferCost: "",

      branchSelect: "",
      branchStock: "",

      vin: "",
      chassisNumber: "",
      engineNumber: "",

      poItem: {
        prodId: "",
        nameTh: "",
        prodCode: "",
        pricePerOne: "",
        amount: 0,
        poTotalPrice: "",
        poDcPer: 0,
        poDcPrice: "",
        poNetPrice: "",
        prodNameTh: "",
        vehicleId: "",
        cost: "",
      },
      optionStatus: [
        {
          name: "ยืนยันแล้ว",
          status: "APV",
        },
        {
          name: "ยกเลิกแล้ว",
          status: "CAN",
        },
      ],

      fieldsPartPO: [
        {
          key: "index",
          label: "ลำดับ",
          sortable: true,
        },
        {
          key: "transferCode",
          label: "รหัสโอน",
          sortable: true,
        },
        {
          key: "branchName",
          sortable: true,
          label: "จากสาขา",
        },
        {
          key: "toBranchName",
          sortable: true,
          label: "ไปยังสาขา",
        },
        {
          key: "transferDate",
          sortable: true,
          label: "วันที่โอน",
        },
        {
          key: "cost",
          sortable: true,
          label: "ราคาต้นทุน",
        },
        // {
        //   key: "action",
        //   label: "เพิ่ม",
        // },
      ],
      filterOnPO: [],
      rowDataPO: [],
      perPagePO: 10,
      currentPagePO: 1,
      filterPO: null,
      pageOptionsPO: [5, 10, 25, 50, 100],
      total_pagesPO: "",
      pagePO: "",
      totalPO: "",
      costPrice: "",

      poDate: "",
      status: "",
      textNote: "",
      chkTransfer: [],

      poVatPer: "",
      poTotalPrice: "",
      poDcPer: 0,
      poDcPrice: 0,
      poNetPrice: "",
      poVatPrice: "",
      poGrandTotalPrice: "",

      productAmount: "",
      productPricePerOne: "",
      productTotalPrice: "",
      productDcPer: "",
      productDcPrice: "",
      productNetPrice: "",
      productVatPer: "",
      productVatPrice: "",
      productGrandTotalPrice: "",

      rowsStock: [],
      optionsPO: [],
      poCode: "",

      nameState: null,

      toBranchId: "",
      apvCode: "",
      apvDate: "",
      note: "",
      cost: "",

      transferdId: "",

      userBranch: "",
      prodId: "",
      filterOn: [],
      rowsPO: [],
      rowsSuppliers: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        {
          key: "index",
          label: "ลำดับ",
          sortable: false,
        },
        {
          key: "poCode",
          sortable: true,
          label: "รหัสสั่งซื้อ",
        },
        {
          key: "supplierNameTh",
          sortable: true,
          label: "ผู้ผลิต",
        },
        {
          key: "poDate",
          sortable: true,
          label: "วันที่สั่งซื้อ",
        },
        {
          key: "grandTotalPrice",
          sortable: true,
          label: "ราคารวม",
        },
        {
          key: "branchName",
          sortable: true,
          label: "สาขา",
        },
        {
          key: "status",
        },
        {
          key: "action",
          label: appConfig.action,
          sortable: false,
        },
      ],
      overlayFlag: false,
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.rowsPO.length;
    },
  },
  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    const arrayBranch = [];
    const arrayBranchAll = [];
    user.userAccessBranch.forEach((item) => {
      arrayBranch.push(item.branchId);
      arrayBranchAll.push(item);
    });
    this.userBranchAccessId = user.branchId;
    this.userBranch = arrayBranch;
    this.optionBranch = arrayBranchAll;
    this.totalRows = this.rowsPO.total;
    if (this.$route.params.apvId) {
      this.getDataDetail();
      //   this.getDetailProd();
    }
  },
  created() {
    // this.getData();
    // this.select()
  },
  methods: {
    /**
     * Search the table data with search input
     */
    numberFormats(x) {
      const formatter = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      return formatter.format(x);
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      // this.currentPage = 1;
    },
    getDataDetail: function() {
      this.overlayFlag = true;
      useNetw
        .get("api/approve-vehicle-transfer/show", {
          params: {
            apvId: atob(this.$route.params.apvId),
          },
        })
        .then((response) => {
          this.userBranchId = {
            branchId: response.data.branchApproveVehicleTransfer.branchId,
            nameTh: response.data.branchApproveVehicleTransfer.branchName,
          };
          this.apvCode = response.data.branchApproveVehicleTransfer.apvCode;
          this.apvDate = response.data.branchApproveVehicleTransfer.apvDate;
          this.note = response.data.branchApproveVehicleTransfer.note;

          this.branchApproveVehicleTransferDetail =
            response.data.branchApproveVehicleTransferDetail;
          this.getBrancApproveTransfer();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err),
            appConfig.swal.type.error
          );
          // this.$router.push("/login");
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    getDetailProd: function(transfer) {
      if (transfer.transferId != null) {
        this.loading = true;
        useNetw
          .get("api/approve-vehicle-transfer/branch-vehicle-transfer-product", {
            params: {
              transferId: transfer.transferId,
              // transferId: atob(this.$route.params.transferId),
            },
          })
          .then((response) => {
            this.rowsDetail = response.data.data;
            this.calculatePo();
          })
          .catch((err) => {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.response.data.message),
              appConfig.swal.type.error
            );
            // this.$router.push("/login");
          })
          .then(() => {
            this.loading = false;
          });
      }
    },
    getBrancApproveTransfer: function() {
      useNetw
        .get("api/approve-vehicle-transfer/branch-vehicle-transfer", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId:
              this.userBranchId != null
                ? this.userBranchId.branchId
                : undefined,
            // this.userBranchAccessId,
            // this.userBranchAccessId != null
            //   ? this.userBranchId.branchId
            //   : undefined,
          },
        })
        .then((response) => {
          this.rowsProduct = response.data.data;
          // this.rowsDetail = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
          // this.$router.push("/login");
        })
        .then(() => {});
    },
    addDetailTransfer() {
      this.overlayFlag = true;
      useNetw
        .post("api/vehicle-transfer/product/store", {
          transferId: atob(this.$route.params.transferId),
          vehicleId: this.poItem.vehicleId,
          toStockId: this.branchStock.stockId,
          transferCost: this.transferCost.replace(/,/g, ""),
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.$refs["modalAmout"].hide();
          this.getDataDetail();
          this.getDetailProd();
          //   this.calculateProduct();
          // this.$router.push({ name: "vehicle-po" });
          //   this.getData();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    approveReceived() {
      this.overlayFlag = true;
      useNetw
        .put("api/received-vehicle/approve", {
          rcvdId: atob(this.$route.params.rcvdId),
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.getDataDetail();
          // this.$router.push({ name: "vehicle-po" });
          //   this.getData();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    submitApproveTransfer() {
      this.overlayFlag = true;
      useNetw
        .post("api/approve-vehicle-transfer/store", {
          branchId: this.userBranchId.branchId,
          transferId: this.chkTransfer,
          note: this.textNote,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
        //   this.getDataDetail();
        //   this.getBrancApproveTransfer();
          this.$refs["modalAmout"].hide();
          this.$router.push({ name: "approve-vehicle-transfer" });
          //   this.getData();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    alert(poItem, index) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ms-2",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "ต้องการลบหรือหรือไม่ ?",
          icon: "warning",
          confirmButtonText: "OK",
          cancelButtonText: "Cancel!",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            this.deleteTransfer(poItem, index);
          }
        });
    },
    updateDetailTransfer() {
      //   this.calculateProduct(poItem);
      this.overlayFlag = true;
      useNetw
        .put("api/vehicle-transfer/product/update", {
          transferdId: this.transferdId,
          vehicleId: this.poItem.vehicleId,
          toStockId:
            this.branchStock != null ? this.branchStock.stockId : undefined,
          transferCost: this.transferCost.replace(/,/g, ""),
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.$refs["modalAmout"].hide();
          this.getDataDetail();
          this.getDetailProd();
          // this.$router.push({ name: "vehicle-po" });
          //   this.getData();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.response.data.message +
                "<br/>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    deleteTransfer: function(poItem) {
      // this.rowsDetail.splice(index, 1);
      this.calculatePo(this.rowsDetail);

      this.loading = true; //skeleton true
      useNetw
        .delete("api/vehicle-transfer/product/delete", {
          data: {
            transferdId: poItem.transferdId,
            transferCost: this.poGrandTotalPrice - poItem.cost,
          },
        })
        .then((response) => {
          // alert("ต้องการลบหรือไม่")
          // this.getPoShow(this.poId);
          // this.rowPoParts.push(this.rowPoArr)
          this.getDataDetail();
          this.getDetailProd();
          Swal.fire(
            appConfig.swal.title.deleterSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    alertSubmit() {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ms-2",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "ต้องการยืนยันรับรถเข้าคลังหรือไม่ !!",
          icon: "warning",
          confirmButtonText: "OK",
          cancelButtonText: "Cancel!",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            this.approveReceived();
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire(
              "Cancelled",
              "ยกเลิกเรียบร้อยแล้ว",
              "error"
            );
          }
        });
    },
    customLabel({ prodCode, productNameTh }) {
      return `[${prodCode}] - ${productNameTh != null ? productNameTh : ""}`;
    },
    showModal() {
      this.$refs.modalAmout.show();
    },
    calculatePo() {
      let poTotalPrice,
        poDcPrice,
        poNetPrice,
        poVatPrice,
        poGrandTotalPrice,
        poTotalCostPrice;

      poTotalPrice = this.rowsDetail.reduce(function(sum, item) {
        //ค่าแรงสุทธิ
        var netPrice = parseFloat(item.price);

        if (!isNaN(netPrice)) {
          return sum + netPrice;
        }
      }, 0);

      this.poTotalPrice = poTotalPrice;
      poDcPrice =
        parseFloat(this.poTotalPrice) * parseFloat(this.poDcPer / 100);
      if (!isNaN(poDcPrice)) {
        this.poDcPrice = poDcPrice;
      }

      // poNetPrice = parseFloat(this.poTotalPrice) - parseFloat(this.poDcPrice);
      // if (!isNaN(poNetPrice)) {
      //   this.poNetPrice = poNetPrice;
      // }

      poTotalCostPrice = this.rowsDetail.reduce(function(sum, item) {
        //ค่าแรงสุทธิ
        var costPrice = parseFloat(item.cost);

        if (!isNaN(costPrice)) {
          return sum + costPrice;
        }
      }, 0);
      this.costPrice = poTotalCostPrice;

      poNetPrice = parseFloat(this.costPrice);
      if (!isNaN(poNetPrice)) {
        this.poNetPrice = poNetPrice;
      }

      poVatPrice = 0; // (this.poNetPrice / 100) * 7;
      if (!isNaN(poVatPrice)) {
        this.poVatPrice = poVatPrice;
      }

      poGrandTotalPrice =
        parseFloat(this.poNetPrice) + parseFloat(this.poVatPrice);
      if (!isNaN(poGrandTotalPrice)) {
        this.poGrandTotalPrice = poGrandTotalPrice;
      }
    },
    // calculateProduct(item) {
    //   item.totalPrice = 1 * parseFloat(item.price);
    //   item.dcPrice =
    //     (parseFloat(item.totalPrice) / 100) * parseFloat(item.dcPer);
    //   item.netPrice = parseFloat(item.totalPrice) - parseFloat(item.dcPrice);
    //   this.prodId = item.prodId;
    //   this.productPricePerOne = parseFloat(this.poItem.pricePerOne);
    //   this.poItem.poTotalPrice = 1 * parseFloat(this.poItem.pricePerOne);
    //   this.poItem.poDcPrice =
    //     parseFloat(this.poItem.poTotalPrice / 100) *
    //     parseFloat(this.poItem.poDcPer);
    //   this.poItem.poNetPrice =
    //     parseFloat(this.poItem.poTotalPrice) -
    //     parseFloat(this.poItem.poDcPrice);
    //   this.poItem.vatPrice =
    //     (parseFloat(this.poItem.poNetPrice) / 100) * parseFloat(this.poVatPer);
    //   this.poItem.poGrandTotalPrice =
    //     parseFloat(this.poItem.poNetPrice) + parseFloat(this.poItem.vatPrice);
    //   this.calculatePo();
    // },
    getDataStock: function() {
      this.branchStock = null;
      this.loading = true;
      useNetw
        .get("api/master/stock-vehicle", {
          params: {
            branchId: this.branchSelect.branchId,
          },
        })
        .then((response) => {
          this.rowsStock = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loading = false;
          // this.isHidden = true;
        });
    },
    onRowSelected(rows) {
      this.selected = rows[0];
      this.getDetailProd(this.selected);
      //   this.alertConfirm(this.selected.roId);
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <b-overlay
          :show="overlayFlag"
          :spinner-variant="this.$overlayVariant"
          :spinner-type="this.$overlayType"
          :rounded="this.$overlayRounded"
        >
          <div class="card">
            <div
              class="col-12 badge"
              style="background-color: #26B99A"
              v-if="this.status === 'APV'"
            >
              <h4 class="mt-2 text-white">
                โอนรถข้ามสาขาแล้ว
              </h4>
            </div>
            <div
              class="col-12 badge"
              style="background-color: #50a5f1"
              v-if="this.status === 'SUBMIT'"
            >
              <h4 class="mt-2 text-white">
                รอยืนยันการโอนรถข้ามสาขา
              </h4>
            </div>
            <div
              class="col-12 badge"
              style="background-color: #e74646"
              v-if="this.status === 'CAN'"
            >
              <h4 class="mt-2 text-white">
                ยกเลิกแล้ว
              </h4>
            </div>
          </div>
          <!-- <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-sm-12 col-md-2"></div>
              </div>
              <div class="row justify-content-md-center">
                <div class="col-sm-12 col-md-12">
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-12">
                      <div class="row">
                        <div class="col-6 col-sm-6 col-md-3">
                          <div class="mb-3 position-relative">
                            จากสาขา:
                            <multiselect
                              v-model="userBranchId"
                              label="nameTh"
                              type="search"
                              :options="optionBranch"
                              :show-labels="false"
                              open-direction="bottom"
                              @input="getBrancApproveTransfer"
                              placeholder=""
                            >
                              <span slot="noResult">ไม่พบข้อมูล</span>
                            </multiselect>
                          </div>
                        </div>
                        <div class="col-6 col-sm-6 col-md-3">
                          <div class="mb-3 position-relative">
                            รหัสโอนรถ:
                            <b-form-input
                              v-model="apvCode"
                              type="search"
                              disabled
                              class="form-control"
                            ></b-form-input>
                          </div>
                        </div>
                        <div class="col-6 col-sm-6 col-md-2">
                          <div class="mb-3 position-relative">
                            วันที่โอน:
                            <date-picker
                              v-model="apvDate"
                              format="YYYY-MM-DD"
                              value-type="format"
                              disabled
                            ></date-picker>
                          </div>
                        </div>
                        <div class="col-6 col-sm-6 col-md-4">
                          <div class="mb-3 position-relative">
                            หมายเหตุ:
                            <b-form-textarea
                              v-model="note"
                              rows="3"
                              disabled
                              max-rows="6"
                            ></b-form-textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->

          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-6">
                  <p class="card-title-desc">รายการรออนุมัติโอนรถข้ามสาขา</p>
                </div>
              </div>
              <div class="col-6 col-sm-6 col-md-3">
                <div class="mb-3 position-relative">
                  จากสาขา:
                  <multiselect
                    v-model="userBranchId"
                    label="nameTh"
                    type="search"
                    :options="optionBranch"
                    :show-labels="false"
                    open-direction="bottom"
                    @input="getBrancApproveTransfer"
                    placeholder=""
                  >
                    <span slot="noResult">ไม่พบข้อมูล</span>
                  </multiselect>
                </div>
              </div>
              <h4 class="text-center" v-if="rowsProduct.length < 1">
                ไม่มีรายการรออนุมัติโอนรถข้ามสาขา
              </h4>
              <div class="table-responsive" v-if="rowsProduct.length > 0">
                <b-table
                  :items="rowsProduct"
                  :fields="fieldsPartPO"
                  responsive="sm"
                  :per-page="perPagePO"
                  :current-page="1"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filterPO"
                  :filter-included-fields="filterOnPO"
                  @filtered="onFiltered"
                  ref="selectableTablePO"
                  selectable
                  @row-selected="onRowSelected"
                  select-mode="single"
                >
                  <template v-slot:cell(index)="optionsPO">
                    <b-form-checkbox
                      v-model="chkTransfer"
                      :value="optionsPO.item.transferId"
                    >
                    </b-form-checkbox>
                  </template>
                </b-table>
                <div class="text-end">
                  <b-button
                    :disabled="chkTransfer == ''"
                    class="btn m-2"
                    variant="primary"
                    type="submit"
                    @click="showModal"
                  >
                    อนุมัติการโอน
                  </b-button>
                </div>
              </div>
            </div>
          </div>

          <div class="card">
            <b-skeleton-wrapper :loading="loading">
              <!-- skeleton  -->
              <template #loading>
                <b-skeleton-table></b-skeleton-table>
              </template>
              <div class="card-body">
                <div class="row">
                  <div class="col-6">
                    <p class="card-title-desc">รายการสินค้า</p>
                  </div>
                </div>
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">รหัสสินค้า</th>
                        <th scope="col">ชื่อสินค้า</th>
                        <th scope="col">สีภายนอก</th>
                        <th scope="col">สีภายใน</th>
                        <th scope="col">จากคลัง</th>
                        <th scope="col">ไปยังคลัง</th>
                        <th scope="col">ราคา</th>
                        <!-- <th scope="col">จำนวน</th> -->
                        <th scope="col">ราคาต้นทุน</th>
                        <!-- <th scope="col">ส่วนลด</th> -->
                        <!-- <th scope="col">ส่วนลดยอด</th> -->
                        <!-- <th scope="col">หลังลด</th> -->
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody v-if="this.rowsDetail.length > 0">
                      <tr v-for="(poItem, index) in rowsDetail" :key="index">
                        <th scope="row">{{ index + 1 }}</th>
                        <td>{{ poItem.prodCode }}</td>
                        <td>{{ poItem.productNameTh }}</td>
                        <td>
                          {{
                            poItem.exteriorColorNameTh != null
                              ? poItem.exteriorColorNameTh
                              : "-"
                          }}
                        </td>
                        <td>
                          {{
                            poItem.interiorColorNameTh != null
                              ? poItem.interiorColorNameTh
                              : "-"
                          }}
                        </td>
                        <td>{{ poItem.fromStockName }}</td>
                        <td>{{ poItem.toStockName }}</td>
                        <td class="">
                          <input
                            v-model="poItem.price"
                            class="form-control text-end number"
                            disabled
                            type="number"
                          />
                        </td>
                        <td class="text-end">
                          <input
                            disabled
                            v-model="poItem.cost"
                            class="form-control text-end number"
                          />
                        </td>
                        <td v-if="status == 'DFT'">
                          <ul class="list-inline mb-0 action">
                            <li class="list-inline-item">
                              <a
                                class="px-2 text-primary"
                                v-b-tooltip.hover
                                title="Edit"
                                @click="showModal(poItem)"
                              >
                                <i class="uil uil-pen font-size-18"></i>
                              </a>
                              <a
                                class="px-2 text-danger"
                                v-b-tooltip.hover
                                title="Delete"
                                @click="alert(poItem, index)"
                              >
                                <i class="uil uil-trash-alt font-size-18"></i>
                              </a>
                            </li>
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </b-skeleton-wrapper>
          </div>

          <!-- <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-6">
                  <p class="card-title-desc">รายการที่อนุมัติโอนรถข้ามสาขา</p>
                </div>
              </div>
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">รหัสสินค้า</th>
                      <th scope="col">ชื่อสินค้า</th>
                      <th scope="col">รหัสรุ่นรถ</th>
                      <th scope="col">ชื่อรุ่นรถ</th>
                      <th scope="col">จากคลัง</th>
                      <th scope="col">ไปยังคลัง</th>
                      <th scope="col">ราคา</th>
                      <th scope="col">ราคาต้นทุน</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody
                    v-for="(data, index) in branchApproveVehicleTransferDetail"
                    :key="index"
                  >
                    <th colspan="10" style="background: #d5d5d5;">
                      <div class="mb-0">
                        รหัสโอน: {{ data.transferCode }} จากคลัง:
                        {{ data.branchName }} ไปยังคลัง:
                        {{ data.toBranchName }} วันที่โอน:
                        {{ data.transferDate }}
                      </div>
                    </th>
                    <tr v-for="(element, index) in data.vehicle" :key="index">
                      <th scope="row">{{ index + 1 }}</th>
                      <td>{{ element.prodCode }}</td>
                      <td>{{ element.productNameTh }}</td>
                      <td>{{ element.modelCode }}</td>
                      <td>{{ element.modelNameTh }}</td>
                      <td>{{ element.fromStockName }}</td>
                      <td>{{ element.toStockName }}</td>
                      <td class="">
                        <input
                          v-model="element.price"
                          class="form-control text-end number"
                          disabled
                          type="number"
                        />
                      </td>
                      <td class="text-end">
                        <input
                          disabled
                          v-model="element.cost"
                          class="form-control text-end number"
                        />
                      </td>
                      <td v-if="status == 'DFT'">
                        <ul class="list-inline mb-0 action">
                          <li class="list-inline-item">
                            <a
                              class="px-2 text-primary"
                              v-b-tooltip.hover
                              title="Edit"
                              @click="showModal(poItem)"
                            >
                              <i class="uil uil-pen font-size-18"></i>
                            </a>
                            <a
                              class="px-2 text-danger"
                              v-b-tooltip.hover
                              title="Delete"
                              @click="alert(poItem, index)"
                            >
                              <i class="uil uil-trash-alt font-size-18"></i>
                            </a>
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div> -->
        </b-overlay>
      </div>
      <b-modal
        ref="modalAmout"
        title="อนุมัติการโอนรถข้ามสาขา"
        hide-footer
        size="lg"
        centered
        :state="nameState"
      >
        <div class="row">
          <div class="col-12 col-sm-6 col-md-12 mt-2 mb-2">
            <label> หมายเหตุ</label>
            <b-form-textarea
              id="textarea"
              v-model="textNote"
              placeholder="หมายเหตุอนุมัติการโอนรถข้ามสาขา"
              rows="10"
              max-rows="10"
            ></b-form-textarea>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12">
            <div class="text-end">
              <button @click="submitApproveTransfer()" class="btn btn-primary">
                บันทึก
              </button>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </Layout>
</template>
